import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import Cookies from 'js-cookie';
import { useStores } from '../../../../mobx/useStores';
import { observer } from 'mobx-react';
import { CheckoutForm } from './checkout-form';
import { Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { RotateLoader } from '@lib/components';
import { genRecaptchaToken } from '../../../../libs/grecaptcha';
import { nanoid } from 'nanoid';
import { getAppearance } from './check-appearance';

export const StripeCard = observer(() => {
  const store = useStores();
  const [clientSecret, setClientSecret] = useState('');
  const [stripeInstance, setStripeInstance] = useState<Stripe | undefined>(undefined);
  
  const locale = store.restaurant.settings.region.locale.toLowerCase() as StripeElementLocale;
  const themes = store.restaurant.settings.payments.stripe?.themes;
  const createPaymentIntent = async (sessionId: string) => {
    if (store.checkout.validatePreOrderDataForStripePayment()) {
      return;
    }

    const { email } = store.checkout.s;
    const { total: amount, currency } = await store.checkout.computePaymentDetails();
    const validateToken = await genRecaptchaToken();
    const data: T.API.StripeLatest.CreateStripeCardPaymentIntentRequest = {
      amount,
      currency,
      customer: { email },
      paymentMethodTypes: ['card'],
      restaurantId: store.restaurant._id,
      sessionId,
      validateToken,
      serviceType: store.order_config.s.service,
      orderId: store.order.getGeneratedOrderId()
    };

    if (store.order_config.s.delivery_provider && ['uber', 'postmates', 'lalamove'].includes(store.order_config.s.delivery_provider)) {
      data.serviceProvider = store.order_config.s.delivery_provider;
      data.collectedFees = store.checkout.computeStripeCollectedFees(store.order_config.s.delivery_provider);
    }

    const response = await store.api.createStripeCardPaymentIntent(data);
    if (response.outcome === 0) {
      store.checkout.s.stripe_paymentIntent_id = response.paymentIntentId;
      setClientSecret(response.paymentIntentClientSecret || '');
    }
  };

  const generateSessionId = () => {
    const cookieName = 'cw.stripe.payment.session';
    let sessionId = Cookies.get(cookieName);
    if (!sessionId) {
      sessionId = nanoid();
      Cookies.set(cookieName, sessionId, { expires: 1 });
    }
    return sessionId;
  };

  const getStripeInstance = async () => {
    const value = store.checkout.getStripeConnectStatus();
    setStripeInstance(value === 'CONNECT' ? window.stripeConnect : window.stripe);
  };

  useEffect(() => {
    if (store.checkout.s.error) {
      return;
    }
    if (store.checkout.s.payment === 'stripe') {
      getStripeInstance().then(() => createPaymentIntent(generateSessionId()));
    }
  }, [store.checkout.s.payment, store.checkout.s.error]);

  useEffect(() => {
    if(clientSecret === ''){
      store.checkout.setStripeCheckoutFormLoading(true);
    } else {
      store.checkout.setStripeCheckoutFormLoading(false);
    }
  }, [clientSecret]);
  return (
    <>
      {clientSecret && stripeInstance ? (
        <Elements stripe={stripeInstance} options={{ clientSecret, locale, appearance: getAppearance(themes, store)  }}>
          <CheckoutForm />
        </Elements>
      ) : (
        <RotateLoader size={3} />
      )}
    </>
  );
});
